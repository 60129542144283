import { createContext, useState, ReactNode, FunctionComponent } from "react";

export type ModalTypes =
  | "create-organization"
  | "create-individual"
  | "sign-in";

export interface ModalContextProps {
  activeModal: ModalTypes | null;
  openModal: (modalId: ModalTypes) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextProps | undefined>(
  undefined
);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: FunctionComponent<ModalProviderProps> = ({
  children,
}) => {
  const [activeModal, setActiveModal] = useState<ModalTypes | null>(null);

  const openModal = (modalId: ModalTypes) => setActiveModal(modalId);
  const closeModal = () => setActiveModal(null);

  return (
    <ModalContext.Provider value={{ activeModal, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
