import axios from "axios";
import { rootUrl } from "../rootUrl";

export type EINData = {
  city: string;
  country: string;
  dba: string | null;
  ein: string;
  name: string;
  state: string;
  types: string;
};

const endpoint = `/client/ein-lookup`;

export const getEinLookup = async (ein: string): Promise<EINData[]> => {
  try {
    const { data } = await axios.get<EINData[]>(
      `${rootUrl}${endpoint}?ein=${ein}`
    );
    return data;
  } catch (error) {
    return [];
  }
};
