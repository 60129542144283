import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

export type CreateClientResponse = {
  id: string;
};

const endpoint = `/client/create`;

export const postCreateClient = async (
  id: string,
  email: string,
  organization: string,
  ein: string,
  accessToken: string
): Promise<CreateClientResponse> => {
  const { data } = await axios.post<CreateClientResponse>(
    `${rootUrl}${endpoint}`,
    { id, email, organization, ein },
    buildAuthHeaders(accessToken)
  );
  return data;
};
