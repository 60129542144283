import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";

export type ClientOnboardingStatusResponse = {
  organization: string;
  website: string;
  logo: string;
  stripeId: string;
  charges: boolean;
  details: boolean;
  link: string;
};

const endpoint = `/client/onboarding-status`;

export const getOnboardingStatus = async (
  accessToken: string,
  rootUrl: string
): Promise<ClientOnboardingStatusResponse> => {
  const { data } = await axios.get<ClientOnboardingStatusResponse>(
    `${rootUrl}${endpoint}`,
    buildAuthHeaders(accessToken)
  );
  return data;
};
