import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";
import { MimeTypes } from "../../components/DragDrop";

type UploadFileResponse = {
  url: string;
};

export type FileType = "CLIENT_LOGO";

export type UploadFileRequest = {
  file: string;
  type: FileType;
  name: string;
  mimeType: MimeTypes;
};

const endpoint = `/client/upload`;

export const postUploadFile = async (
  body: UploadFileRequest,
  accessToken: string
): Promise<UploadFileResponse> => {
  const { data } = await axios.post<UploadFileResponse>(
    `${rootUrl}${endpoint}`,
    body,
    buildAuthHeaders(accessToken)
  );
  return data;
};
