import { styled } from "styled-components";
import { Heading, Spinner, Text } from "@chakra-ui/react";
import { AmountChart } from "./charts/AmountChart";
import { ReachChart } from "./charts/ReachChart";
import { ChartFilterSelector } from "../Reusable";
import { colors } from "../../colors";
import { useEffect, useState } from "react";
import {
  GetClientAnalyticsResponse,
  getClientAnalytics,
} from "../../api/clientUser/getClientAnalytics";
import { useAuth } from "../../hooks/useAuth";
import { Show } from "../Show";

const Content = styled.div`
  margin-top: 3rem;
`;

const ChartsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const Analytics = () => {
  const { accessToken } = useAuth();
  const [isProcessing, setIsProcessing] = useState(true);
  const [campaignNames, setCampaignNames] = useState<string[]>([
    "All Campaigns",
  ]);
  const [analyticsData, setAnalyticsData] =
    useState<GetClientAnalyticsResponse>({
      amounts: [],
      campaigns: [],
      contacts: [],
    });
  // selectedCampaign is formatted as "Campaign Name - truncated Campaign ID"
  const [selectedCampaignName, setSelectedCampaignName] = useState<string>("");

  useEffect(() => {
    setIsProcessing(true);
    const fetchAnalytics = async () => {
      if (!accessToken) return;
      const campaign = selectedCampaignName
        ? analyticsData.campaigns.find((c) => c.name === selectedCampaignName)
        : undefined;
      const analytics = await getClientAnalytics(accessToken, campaign?.id);
      setAnalyticsData(analytics ?? []);
      setCampaignNames(
        ["All Campaigns"].concat(
          analytics.campaigns.map((campaign) => `${campaign.name}`)
        )
      );
      setIsProcessing(false);
    };
    fetchAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, selectedCampaignName]);
  return (
    <Content>
      <Heading>Analytics</Heading>
      <Text>Visualize your fundraising results over time.</Text>
      <Show
        when={!isProcessing}
        whenFalseContent={<Spinner style={{ margin: "1rem 0" }} />}
      >
        <ChartFilterSelector $color={colors.gold}>
          <select
            value={selectedCampaignName}
            onChange={(e) => setSelectedCampaignName(e.target.value)}
          >
            {campaignNames.map((frame) => (
              <option key={frame} value={frame}>
                {frame.charAt(0).toUpperCase() + frame.slice(1)}
              </option>
            ))}
          </select>
        </ChartFilterSelector>
        <ChartsContainer>
          <AmountChart data={analyticsData.amounts} />
          <ReachChart data={analyticsData?.contacts} />
        </ChartsContainer>
      </Show>
    </Content>
  );
};
