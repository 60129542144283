import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

export type ClientAnalyticsAmount = {
  createdAt: Date;
  amount: number;
};

export type ClientAnalyticsContact = {
  createdAt: Date;
};

export type ClientAnalyticsCampaign = {
  id: string;
  name: string;
};

export type GetClientAnalyticsResponse = {
  amounts: ClientAnalyticsAmount[];
  campaigns: ClientAnalyticsCampaign[];
  contacts: ClientAnalyticsContact[];
};

const endpoint = `/client/analytics`;

export const getClientAnalytics = async (
  accessToken: string,
  campaignId?: string
): Promise<GetClientAnalyticsResponse> => {
  const { data } = await axios.get<GetClientAnalyticsResponse>(
    `${rootUrl}${endpoint}${campaignId ? `?campaignId=${campaignId}` : ""}`,
    buildAuthHeaders(accessToken)
  );
  return data;
};
