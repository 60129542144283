import React, { useState, useRef, useEffect, Fragment } from "react";
import styled from "styled-components";
import {
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Box,
  Text,
  VStack,
  Divider,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { colors } from "../colors";
import { EINData, getEinLookup } from "../api/clientUser/getEinLookup";
import { Show } from "./Show";

const StyledInputGroup = styled(InputGroup)``;

const SearchResultBox = styled(Box)`
  max-width: 25rem;
  z-index: 1;
  background-color: ${colors.white};
  width: 100%;
  max-height: 18.75rem;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
`;

export type EinSearchBarProps = {
  onSelectResult: (result: EINData) => void;
};

export const EinSearchBar = (props: EinSearchBarProps) => {
  const { onSelectResult } = props;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<EINData[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = async () => {
    setIsSearching(true);
    const results = await getEinLookup(searchQuery.replace("-", ""));
    setSearchResults(results);
    setShowResults(true);
    setIsSearching(false);
  };

  const handleSelectResult = (result: EINData) => {
    onSelectResult(result);
    setShowResults(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    // Focus the input element once the component is mounted
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const formatEIN = (ein: string): string => {
    // Assuming EIN is always a 9-digit string, format as XX-XXXXXXX
    return ein.replace(/^(\d{2})(\d{7})$/, "$1-$2");
  };

  const noResults = (
    <Box textAlign="center" mt="4">
      <Text>
        No results were found. Please contact{" "}
        <span style={{ color: colors.gold }}>hello@kissgives.com</span> to
        register your organization!
      </Text>
    </Box>
  );

  return (
    <div ref={wrapperRef} style={{ marginBottom: "1rem" }}>
      <StyledInputGroup>
        <Input
          ref={inputRef}
          pr="4.5rem"
          type="text"
          placeholder="Enter your 501(c)(3) EIN #"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          focusBorderColor={colors.gold + "60"}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleSearch}>
            {isSearching ? <Spinner size="sm" /> : <SearchIcon />}
          </Button>
        </InputRightElement>
      </StyledInputGroup>
      <Show when={showResults}>
        <Show when={searchResults.length > 0} whenFalseContent={noResults}>
          <SearchResultBox>
            <VStack align="stretch">
              {searchResults.map((result, index) => (
                <Fragment key={index}>
                  <Flex
                    p={4}
                    _hover={{ bg: colors.gold + "30" }}
                    cursor="pointer"
                    justifyContent="space-between"
                    onClick={() => handleSelectResult(result)}
                  >
                    <Box>
                      <Text fontWeight="bold">{result.name}</Text>
                      <Text fontSize="sm">{formatEIN(result.ein)}</Text>
                    </Box>
                    {result.city && result.state && (
                      <Text
                        fontSize="sm"
                        textAlign="right"
                        color={colors.gold}
                        fontWeight={600}
                      >{`${result.city}, ${result.state}`}</Text>
                    )}
                  </Flex>
                  <Divider border={`1px solid ${colors.black + "30"}`} />
                </Fragment>
              ))}
            </VStack>
          </SearchResultBox>
        </Show>
      </Show>
    </div>
  );
};
