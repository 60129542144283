import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { rootUrl } from "../rootUrl";

export type CreateUserResponse = {
  id: string;
};

const endpoint = `/user/create`;

export const postCreateUser = async (
  id: string,
  email: string,
  name: string,
  accessToken: string
): Promise<CreateUserResponse> => {
  const { data } = await axios.post<CreateUserResponse>(
    `${rootUrl}${endpoint}`,
    { id, email, name },
    buildAuthHeaders(accessToken)
  );
  return data;
};
